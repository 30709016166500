.Page{

    position: relative;
    box-sizing: border-box;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    &__header{
        position: relative;
        box-sizing: border-box;
        z-index: 3;
    }

    &__body{
        position: relative;
        box-sizing: border-box;
        z-index: 1;
        flex: 1;
    }

    &__footer{
        position: relative;
        box-sizing: border-box;
        z-index: 2;
    }

}
