.Pagination{

    position: relative;

    &--element{
        position: relative;
    }

    .Pagination--mod{
        @extend .Pagination;
    }

}
